import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../../components/Layout";
import EventInList from "../../components/EventInList";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Conscious Events - Lagos, Portugal`}</h1>
    <h2>{`Today (July 22nd)`}</h2>
    <EventInList event={{
      id: 1,
      title: "10:00am: Morning Yoga",
      description: "This is *the best* event..."
    }} mdxType="EventInList" />
    <EventInList event={{
      id: 2,
      title: "6:00pm: Fullmoon Sunset Silent Beach Party",
      description: "Hello humans of the mother nature, we love to invite you for an experience of the magical Fullmoon 🌕 with a sunshine sunset 🌅 on headphones 🎧 on a beautiful beach! \n\nEnergy: Early Bird 15 🌕, Regular 20 🌕 "
    }} mdxType="EventInList" />
    <h2>{`Tomorrow (July 23rd)`}</h2>
    <p>{`No events.`}</p>
    <h2>{`Sunday, July 24th`}</h2>
    <EventInList event={{
      id: 3,
      title: "6:00pm: Contemporary Dance Improvisation & Guided Journeys",
      description: "In ten weekly sessions we work with movement scores and guided journeys to explore the relation between the self, the other and the space. Tools such as floorwork, release technique, partnering and composition are being offered for you to discover the playfulness of dance improvisation. The practice is open for beginners and welcomes all bodies ready to grow through movement research and exploration.\n\nExchange: 80€ for 10 classes / 10€ drop in"
    }} mdxType="EventInList" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      